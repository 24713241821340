var share = {};

share.document = (doc_url, doc_title) => {
    if (!doc_url) {
        return false;
    }

    doc_title = doc_title.replace(/ /g, '_');

    var data = {
        doc_url: doc_url,
        doc_title: doc_title,
    };

    /* PUSHER */
    // realtime.channel.trigger('client-share-document', { ...data, local: false });
    // realtime.onShareDocument({ ...data, local: true });

    /* FIREBASE */
    fbDB.update(fbDB.teamPath + '/sharedDoc/' + doc_title, data);

    $.request('onShareDocument', {
        data,
        success: function (data) {
            // const eventData = { callback: 'hideShareDocumentBtnIfAlreadyShared' };
            // realtime.channel.trigger('client-refresh-view-data', { ...eventData, local: false });
            // realtime.onRefreshViewData({ ...eventData, local: true });
        },
        error: function (error) {
            console.log(error);
        },
        complete: function () {},
    });
};

share.hideShareButtonIfShared = () => {
    if (!viewData.team.saved_documents) {
        viewData.team.saved_documents = [];
    }

    const hideShareBtnInImageBox = () => {
        const url = $('.playroom-imagebox-share').data('url');
        const title = $('.playroom-imagebox-share').data('title');
        let isShared;

        isShared = viewData.team.saved_documents.find(
            (document) => document.title === title && document.url === url,
        );

        if (isShared) {
            $('.playroom-imagebox-share').addClass('is-hidden');
        } else {
            $('.playroom-imagebox-share').removeClass('is-hidden');
        }
    };

    const hideShareBtnInIpad = () => {
        const hideShareBtnInConnectU = () => {
            const title = $('.ipad-connectu-share').data('title');
            const url = $('.ipad-connectu-share').data('url');

            const isShared = viewData.team.saved_documents.find(
                (document) => document.title === title && document.url === url,
            );
            if (isShared) {
                $('.ipad-connectu-share').hide();
            } else {
                $('.ipad-connectu-share').show();
            }
        };

        const hideShareBtnInBrowser = () => {
            const title = $('.ipad-browser-share').data('title');
            const url = $('.ipad-browser-share').data('url');

            const isShared = viewData.team.saved_documents.find(
                (document) => document.title === title && document.url === url,
            );
            if (isShared) {
                $('.ipad-browser-share').hide();
            } else {
                $('.ipad-browser-share').show();
            }
        };

        hideShareBtnInConnectU();
        hideShareBtnInBrowser();
    };

    hideShareBtnInImageBox();
    hideShareBtnInIpad();
};
