(function ($) {
    /* ================================== LISTENERS ================================== */

    let changeLeaderTimeout = false;

    /* WAITING ROOM */
    const onPlayersStateChanged = (e, eData) => {
        const players = eData?.data;
        fbDB.players = players;
        fbDB.playerOnline = Object.keys(players)
            .filter((key) => players[key].onlineState)
            ?.map((player_id) => parseInt(player_id));

        // change leader after 7s leader disconnected
        if (fbDB.playerOnline.indexOf(fbDB.leader) == -1) {
            if (changeLeaderTimeout) {
                console.log('clear change leader timeout : ', changeLeaderTimeout);
                clearTimeout(changeLeaderTimeout);
            }

            changeLeaderTimeout = setTimeout(function () {
                if (
                    fbDB.playerOnline.indexOf(fbDB.leader) == -1 &&
                    viewData.user.id == fbDB.playerOnline[0]
                ) {
                    changeLeader();
                } else {
                    console.log('cancel request change leader');
                }
            }, 7000);
        }

        if ($('#waiting-room').length > 0) {
            checkReadyToStart(players);
        } else if ($('#lobby-page').length && fbDB.playerOnline.indexOf(viewData.user.id) != -1) {
            location.href = '/main-office';
        } else {
            // set timeout due SPA ?
            setTimeout(function () {
                renderViewPlayersInRoom();
            }, 1000);
        }

        setTimeout(function () {
            renderPlayersInNavbar();
        }, 1000);

        // check if I am offline
        if (players[viewData.user.id]?.onlineState == false) {
            console.log('I am offline');
            showMessage(viewData.strings.disconnected);
        }
    };

    const onChatStateChanged = (e, eData) => {
        const message = eData?.data;
        appendChat(message);
    };

    const onTeamStateChanged = (e, eData) => {
        if ($('#waiting-room').length > 0) {
            // change team name
            const teamName = eData?.data?.teamname;
            $('#team-name').val(teamName);

            // start game
            if (eData?.data?.started) {
                startGame();
            }
        }

        fbDB.leader = eData?.data?.leader;

        // change leader
        if (
            eData?.data?.leaderEmail == viewData.user.email &&
            viewData.team.leader_email !== viewData.user.email
        ) {
            console.log('change leader');
            const currentPage = getCurrentPage();
            window.location.href = `/${currentPage}?new-leader=1`;
        }

        if (eData.data?.currentPhase) {
            onGoToNextPhase(eData.data?.currentPhase);
        }

        if (eData.data?.triggerLoadInbox && eData.data.triggerLoadInbox == 1) {
            realtime.loadInbox();
        }

        // finish game
        if (eData?.data?.finished) {
            if (fbDB.finished) {
                return;
            }
            finishedGame();
            fbDB.finished = true;

            // inform pending player if game is finished
            if (viewData.user.isPendingUser == 1) {
                showMessage(viewData.strings.game_finished);
                setTimeout(function () {
                    $('.js-cancel-join').trigger('click');
                }, 2000);
            }
        }

        // unlock data room
        if (eData?.data?.unlockedDataRoom) {
            unlockDataRoom();
        }

        // call CEO
        if (eData?.data?.callingCEO) {
            callCEO(eData.data.callingCEO);
        }

        // assign new leader
        if (eData?.data?.requestChangeLeader) {
            if (viewData.user.id == eData.data.requestChangeLeader && viewData.user.id != fbDB.leader) {
                console.log('user assigned as new leader');
                changeLeader();
            }
        }

        // reload page if no longer leader
        if (viewData.user.isLeader && fbDB.leader != viewData.user.id) {
            console.log('reload no longer leader');
            location.reload();
        }
    };

    const onPlayerLeave = (e, eData) => {
        const $userEl = $('#user-' + eData.data);
        $userEl.remove();

        if (eData.data == viewData.user.id) {
            console.log('I am leave / kicked');
            $.request('onSetTeamMemberInactive', {
                data: {
                    teamId: viewData.team.id,
                    userId: viewData.user.id,
                },
                success: function (res) {
                    //console.log('team online counter updated');
                    logOut();
                },
            });

            const logOut = function () {
                $.request('onKick', {
                    success: function (res) {
                        let isLeave = sessionStorage.getItem('leftGame') == 1;
                        window.location.href = '/?' + (isLeave ? 'leave' : 'kick') + '=1';
                        sessionStorage.removeItem('leftGame');
                    },
                    error: function () {
                        console.log('error occurend');
                    },
                });
            };
        }
    };

    const onShareDocument = (e, eData) => {
        renderSharedDocument(eData.data);
    };

    const onRemoveDocument = (e, eData) => {
        removeSharedDocument(eData.data);
    };

    const onGoToNextPhase = (phase) => {
        phase = parseInt(phase);
        if (phase < 2) {
            return;
        }

        if (fbDB.currentPhase == phase) {
            return;
        }
        goToNextPhase(phase);
    };

    const onRequestJoinGame = (e, eData) => {
        if (viewData.user.isLeader) {
            const userProps = eData.data;
            renderNewPlayerJoinRequest(userProps.key, userProps.val.avatar, userProps.val.name);

            // add to pending players list
            fbDB.pendingPlayers.push(userProps.key);
        }
    };

    const onRejectJoinGame = (e, eData) => {
        // remove from pending players
        var pIndex = fbDB.pendingPlayers.indexOf(eData.data.key);
        if (pIndex !== -1) {
            fbDB.pendingPlayers.splice(pIndex, 1);
        }

        if (
            viewData.user.id == eData.data.key &&
            (!fbDB.players.hasOwnProperty(viewData.user.id) ||
                (fbDB.players.hasOwnProperty(viewData.user.id) &&
                    fbDB.playerOnline.indexOf(viewData.user.id) == -1))
        ) {
            alert('You have been denied/canceled.');
            location.href = '/';
        } else if (viewData.user.isLeader) {
            $('#new-join-' + eData.data.key).remove();
            if (fbDB.pendingPlayers.length == 0) {
                $('.teamMembers').removeClass('is-new-members');
            }
        }
    };

    /* ================================== ACTIONS ================================== */
    const checkReadyToStart = (players) => {
        let allReady = true;

        let playerOnline = Object.values(players).filter((player) => player.onlineState);

        $.request('onUpdateOnlineCounter', {
            data: {
                online_counter: playerOnline.length,
            },
            success: function (res) {
                //console.log('team online counter updated');
            },
            error: function () {
                console.log('error occurend');
            },
        });

        Object.keys(players).forEach((key) => {
            const player = players[key];
            addMember({ ...player, id: key });
            if (player.ready) {
                $('#user-' + key).addClass('is-ready');
                $('.teamMembers__item.player-' + key).addClass('is-ready');
            } else {
                allReady = false;
                $('#user-' + key).removeClass('is-ready');
                $('.teamMembers__item.player-' + key).removeClass('is-ready');
            }

            if (key == viewData.user.id) {
                const $readyBtn = $('#ready-btn');
                $readyBtn.text($readyBtn.data(fbDB.players[viewData.user.id]?.ready ? 'cancel' : 'ready'));

                fbDB.players[viewData.user.id]?.ready
                    ? $readyBtn.addClass('is-ready')
                    : $readyBtn.removeClass('is-ready');
            }
        });
    };

    const addMember = (member) => {
        if (!member.onlineState) {
            if ($('#user-' + member.id).length) {
                $('#user-' + member.id).remove();
            }
            return;
        }

        if ($('#user-' + member.id).length) return;

        const userEl =
            `<div class="game-player__item" id="user-` +
            member.id +
            `">
                            <div class="game-player__avatar" style="background-image: url('` +
            (member.avatar
                ? member.avatar
                : 'https://cdn-images-1.medium.com/fit/c/100/100/1*3ApyPDl5_ovc87cyvX7MMw.jpeg') +
            `');">
                            </div>
                            <div class="game-player__name">
                                ` +
            (member.name ? member.name : '') +
            `
                            </div>` +
            (viewData.user.isLeader && viewData.user.id != parseInt(member.id)
                ? `<button class="btn btn-primary game-player__delete" user-id="` +
                  member.id +
                  `" player-name="` +
                  (member.name ? member.name : '') +
                  `">Delete</button>`
                : '') +
            `</div>`;

        $('#waiting-room-registered-users').append(userEl);
    };

    const appendChat = (data) => {
        var n = document.createTextNode(data.sender);
        var m = document.createTextNode(data.message);
        AspirationUI.append(n, m, data.avatar);
    };

    const startGame = () => {
        var gameProgressCreated = false;

        const createGameProgress = () => {
            // make sure game progress is created, retry if this fails
            $.post('game-progress/create', function () {
                gameProgressCreated = true;
            }).fail(function (xhr, status, error) {
                setTimeout(createGameProgress, 1000);
            });
        };

        const startCountdown = () => {
            let counter = 5;
            const counterInterval = setInterval(() => {
                counter = counter - 1;
                $('.game-start__counter span').html(counter);

                if (counter === 0) {
                    clearInterval(counterInterval);
                    $('.game-start__counter').addClass('is-loading');
                    document.location.href = '/main-office';
                    //$('#go-to-main-office').click();
                }
            }, 1000);

            $('.sidepopup').addClass('is-hidden');
            $('.game-start').addClass('is-active');
            $('.game-start__counter span').html(counter);
        };

        createGameProgress();
        startCountdown();
        analytic.logEvent('start_game', {});
    };

    const renderViewPlayersInRoom = () => {
        const avatarHtml = (player) => {
            return `
            <img
                src="${player.avatar ? player.avatar : helper.defaultAvatar}"
                alt="photo"
                title="${player.name}"
            />
        `;
        };

        const currentPage = getCurrentPage();
        const players = fbDB.playerOnline.map(function (player_id) {
            return fbDB.players[player_id];
        });

        if (currentPage === 'main-office') {
            ``;
            const mainOfficePlayers = players
                .filter((player) => player.currentPage === 'main-office')
                .map((player) => avatarHtml(player));
            $('.playerinfo-members').html('').append(mainOfficePlayers);

            const mainDeskPlayers = players
                .filter(
                    (player) =>
                        player.currentPage === 'main-desk' || player.currentPage === 'archive-document',
                )
                .map((player) => avatarHtml(player));
            $('#playroom-main-desk').html('').append(mainDeskPlayers);

            const dataRoomPlayers = players
                .filter((player) => player.currentPage === 'data-room')
                .map((player) => avatarHtml(player));
            $('#playroom-secretary-room').html('').append(dataRoomPlayers);

            const conferenceRoomPlayers = players
                .filter((player) => player.currentPage === 'conference-room')
                .map((player) => avatarHtml(player));
            $('#playroom-conference-room').html('').append(conferenceRoomPlayers);
        } else if (currentPage === 'main-desk') {
            $('.playerinfo-members')
                .html('')
                .append(
                    players
                        .filter(
                            (player) =>
                                player.currentPage === 'main-desk' ||
                                player.currentPage === 'archive-document',
                        )
                        .map((player) => avatarHtml(player)),
                );

            $('.escape-members')
                .html('')
                .append(
                    players
                        .filter((player) => player.currentPage === 'main-office')
                        .map((player) => avatarHtml(player)),
                );
        } else if (currentPage === 'archive-laptop') {
            $('.playerinfo-members')
                .html('')
                .append(
                    players
                        .filter(
                            (player) =>
                                player.currentPage === 'main-desk' || player.currentPage === 'archive-laptop',
                        )
                        .map((player) => avatarHtml(player)),
                );
        } else if (currentPage === 'archive-document') {
            $('.playerinfo-members')
                .html('')
                .append(
                    players
                        .filter(
                            (player) =>
                                player.currentPage === 'main-desk' ||
                                player.currentPage === 'archive-document',
                        )
                        .map((player) => avatarHtml(player)),
                );
        } else if (currentPage === 'conference-room') {
            $('.playerinfo-members')
                .html('')
                .append(
                    players
                        .filter((player) => player.currentPage === 'conference-room')
                        .map((player) => avatarHtml(player)),
                );

            $('.escape-members')
                .html('')
                .append(
                    players
                        .filter((player) => player.currentPage === 'main-office')
                        .map((player) => avatarHtml(player)),
                );
        } else if (currentPage === 'data-room') {
            $('.playerinfo-members')
                .html('')
                .append(
                    players
                        .filter((player) => player.currentPage === 'data-room')
                        .map((player) => avatarHtml(player)),
                );

            $('.escape-members')
                .html('')
                .append(
                    players
                        .filter((player) => player.currentPage === 'main-office')
                        .map((player) => avatarHtml(player)),
                );
        }
    };

    const renderSharedDocument = (doc) => {
        // add document to toolbar
        var documentExist = false;
        // var documents = []

        $('.toolbox-popup-documents .swiper-slide').each((index, el) => {
            const title = $(el).data('title');
            const url = $(el).attr('doc_url');

            if (title.replace(/ /g, '_') == doc.doc_title && url == doc.doc_url) {
                documentExist = true;
            }
        });

        if (!documentExist) {
            var img_name = '/' + doc.doc_url.split('/').pop();
            $.request('onGetThumbnail', {
                data: { doc_url: helper.safeUrl(doc.doc_url /*img_name*/) },
                success: function (res) {
                    // Swiper index also start from 0
                    const newIndex = toolboxPopupSlider.slides.length;
                    const { doc_url, doc_title } = doc;
                    const slideMarkup = `<div
                        class="swiper-slide"
                        data-title="${doc_title}"
                        style="background-image:url('${helper.safeUrl(doc_url)}')"
                        doc_url="${doc_url}"></div>`;
                    const thumbMarkup = `<button
                        class="toolbox-popup-thumb-item ${newIndex ? 'is-active' : ''}"
                        style="background-image:url('${helper.safeUrl(res.result)}')"
                        data-url="${doc_url}"
                        data-title="${doc_title}"
                        ></button>`;
                    const fullscreenMarkup = `<img
                        src="${doc_url}"
                        data-link="${doc_url}" 
                        data-title="${doc_title}"
                        alt="${doc_title}"
                    >`;

                    $('.toolbox-popup-thumb-wrapper').append(thumbMarkup);
                    toolboxPopupSlider.appendSlide(slideMarkup);
                    $('.toolbox-fullscreen-popup .toolbox-fullscreen').append(fullscreenMarkup);
                    toolboxPopupSlider.update();
                    syncSliderControls();

                    viewData.team.saved_documents = viewData.team.saved_documents ?? [];
                    viewData.team.saved_documents.push({
                        thumb: res.result,
                        url: doc_url,
                        title: doc_title,
                    });
                },
                error: function (error) {},
            });
        }

        // increase notification
        $('#notification-badge-document').show();
        $('#notification-badge-total').show();

        var count = $('#notification-document').data('count');
        count++;

        $('#notification-document').text(`+${count}`);
        $('#notification-document').data('count', count);
        calculateNotificationTotal();
    };

    const removeSharedDocument = (doc) => {
        var slidesIndex;

        $('.toolbox-popup-documents .swiper-slide').each((index, element) => {
            if ($(element).attr('doc_url') == doc.doc_url && $(element).data('title') == doc.doc_title) {
                return (slidesIndex = index);
            }
        });

        $(`.toolbox-popup-thumb-item[data-url="${doc.doc_url}"][data-title="${doc.doc_title}"]`).remove();
        $(`.toolbox-fullscreen-popup img[src="${doc.doc_url}"][data-title="${doc.doc_title}"]`).remove();

        // console.log('slidesIndex', slidesIndex)
        if (typeof slidesIndex !== 'undefined') {
            toolboxPopupSlider.removeSlide(slidesIndex);
            toolboxPopupSlider.update();
            syncSliderControls();

            if (!toolboxPopupSlider.slides.length) {
                $('.toolbox-popup-control-title').text($('.toolbox-popup-control-title').data('no-document'));
            }
        }

        viewData.team.saved_documents = viewData.team.saved_documents.filter(function (obj) {
            return obj.url != doc.doc_url;
        });
    };

    const goToNextPhase = (phase) => {
        switch (phase) {
            case 2:
                if (phase1Passed) {
                    return;
                }
                $('#data-room-btn .playroom-room-name').removeClass('is-disabled');
                phase1Passed = true;
                break;
            case 3:
                if (phase2Passed) {
                    return;
                }
                phase2Passed = true;
                break;
            case 4:
                if (phase3Passed || $('#brief-title-4').hasClass('shown')) {
                    return;
                }
                phase3Passed = true;
                $('#brief-title-4').addClass('shown');

            default:
                break;
        }

        // show briefing current phase
        $('.toolbox-briefing .briefing-step.is-active').removeClass('is-active');
        $('#briefing-step-' + phase)
            .addClass('is-active')
            .show();
        increaseNotificationBriefing();
        gameLogic.showSingleBrief(phase);

        // visualize phase
        $('.navbar-info')
            .removeClass('is-phase-' + (phase - 1))
            .addClass('is-phase-' + phase);

        if (phase > 3) {
            phase = 3;
        }
        $('.email-compose .phase').text(` (phase ${phase})`);
        $('.email-compose').data('target-class', `template-phase-${phase}`);
        $('.template-phase-2 button[type="submit"]').attr('disabled', 'disabled');
    };

    const increaseNotificationBriefing = () => {
        // increase notification briefing
        $('#notification-badge-briefing').show();
        // $('#notification-badge-total').show();
        var count = $('#notification-briefing').data('count');
        count++;
        $('#notification-briefing').text(`+${count}`);
        $('#notification-briefing').data('count', count);
        calculateNotificationTotal();

        $.request('onIncreaseNotificationBriefing', {
            data: {},
            success: function (res) {},
            error: function (error) {},
        });
    };

    const finishedGame = () => {
        setTimeout(() => {
            var $video = $('#outro-video');
            var $laptop = $('.laptop');
            if ($video.length) {
                setupOutroVideo();
            }
        }, 1000);

        const markAsFinish = () => {
            gameLogic.finished = true;
            localStorage.setItem('teamId', viewData.team.id); // used by end-page.js

            $.request('onFinishGame', {
                data: {},
                success: function (res) {},
                error: function (error) {
                    // make sure this request is submitted
                    setTimeout(markAsFinish, 1000);
                },
            });
        };

        markAsFinish();
    };

    const unlockDataRoom = () => {
        viewData.team.data_room_unlocked = true;
    };

    const callCEO = (audioUrl) => {
        $('.toolbox').removeClass('is-open');
        $('.toolbox-phone').addClass('is-open');

        const $fieldset = $('.phone-call fieldset');
        $('.phone').addClass('is-calling');
        $fieldset.prop('disabled', true);

        const $player = $('#voicemail-player');
        if (audioUrl) {
            $player.attr('src', audioUrl);
            $player[0].load();
        }

        setTimeout(() => {
            $('.phone').addClass('is-voicemail');
        }, 1000);
    };

    const changeLeader = () => {
        console.log('request change leader');
        $.request('onChangeLeader', {
            data: {},
            success: function (res) {
                console.log('success change leader');
                fbDB.remove(fbDB.teamPath + '/requestChangeLeader');
            },
            error: function (error) {},
        });
    };

    const renderPlayersInNavbar = () => {
        const $wrapper = $('.teamMembers__list');
        $wrapper.html('');
        const isCurrentLeader = fbDB.leader == viewData.user.id;
        fbDB.playerOnline.forEach(function (player_id) {
            const player = fbDB.players[player_id];
            const isLeader = fbDB.leader == player_id;
            const playerHtml =
                `<div class="teamMembers__item player-${player_id} ` +
                (isLeader ? `is-teamlead ` : ``) +
                (player.ready ? `is-ready` : ``) +
                `">` +
                `<span class="leaderStar">Leader</span>
                <div class="teamMembers__avatar"  style="background-image: url('${player.avatar}');"></div>
                <div class="teamMembers__detail">` +
                (isLeader ? `<span class="leaderLabel">Leader</span>` : ``) +
                `<span class="userNameBlock">${player.name}</span>
                </div>` +
                (isCurrentLeader
                    ? `<div class="teamMembers__action">
                    <button class="teamMembers__action--toggle js-toggle-team-action" title="Options"><span>Options</span></button>
                    <div class="teamMembers__action--menu">
                        <button class="teamMembers__action--btn js-assign-leader" title="Assign as leader" player_id="${player_id}">Assign as leader</button>
                        <button class="teamMembers__action--btn js-remove-player" title="Remove player" player_id="${player_id}">Remove Player</button>
                    </div>
                </div>`
                    : ``) +
                `</div>`;
            $wrapper.append(playerHtml);
        });

        $wrapper.addClass(isCurrentLeader ? 'leadView' : 'teamView');
        $('#count-players').text(fbDB.playerOnline.length);

        // check if current page is waiting room
        const isWaitingRoomPage = $('#waiting-room').length > 0;
        if (isWaitingRoomPage) {
            $('.teamMembers').removeClass('is-toggleable').addClass('is-fixed');
        }
    };

    const getCurrentPage = () => {
        return window.location.pathname.replace('/', '');
    };

    const renderNewPlayerJoinRequest = (user_id, avatar, name) => {
        let $newPlayer = `
        <div class="teamMembers__item new-member-list" id="new-join-${user_id}">
            <div class="teamMembers__avatar"  style="background-image: url('${avatar}');"></div>
            <div class="teamMembers__detail">
                ${name}
            </div>
            <div class="teamMembers__entry">
                <button class="btn small btn-accept" title="Accept" user_id=${user_id}>
                    <span>Accept</span>
                </button>
                <button class="btn small btn-secondary js-close-notification btn-reject" title="Deny" user_id=${user_id}>
                    <span>Deny</span><i class="far fa-times"></i>
                </button>
            </div>
        </div>
        `;
        if (!$('#new-join-' + user_id).length) {
            $('.teamMembers__newmember').append($newPlayer);

            $('.teamMembers').addClass('is-new-members').removeClass('is-hidden');
            $('.teamMembers__notifications').removeClass('is-hidden');
        }
    };

    bdjs.listen('bd__teamStateChanged', onTeamStateChanged);
    bdjs.listen('bd__playersStateChanged', onPlayersStateChanged);
    bdjs.listen('bd__chatsStateChanged', onChatStateChanged);
    bdjs.listen('bd__playerLeave', onPlayerLeave);
    bdjs.listen('bd__shareDocument', onShareDocument);
    bdjs.listen('bd__removeDocument', onRemoveDocument);
    bdjs.listen('bd__requestJoinGame', onRequestJoinGame);
    bdjs.listen('bd__rejectJoinGame', onRejectJoinGame);

    if (window.location.href.includes('new-leader=1')) {
        const currentPage = getCurrentPage();
        history.pushState({}, null, `/${currentPage}`);
    }

    $(document).on('click', '.js-assign-leader', function () {
        const player_id = $(this).attr('player_id');
        const player = fbDB.players[player_id];

        $('#assign-leader-player-name').html(player.name);
        $('#confirm-assign-leader').attr('player_id', player_id);
        $('#assign-leader-popup').addClass('is-open');
    });

    $(document).on('click', '#confirm-assign-leader', function () {
        console.log('assign new leader');
        const player_id = $(this).attr('player_id');
        if (fbDB.playerOnline.includes(parseInt(player_id))) {
            /* FIREBASE */
            fbDB.update(fbDB.teamPath, { requestChangeLeader: player_id });
            setTimeout(function () {
                fbDB.remove(fbDB.teamPath + '/requestChangeLeader');
            }, 2000);
        } else {
            showMessage(viewData.strings.player_offline);
        }

        $('#assign-leader-popup').removeClass('is-open');
    });

    $(document).on('click', '#cancel-assign-leader, #cancel-remove-player', function () {
        $(this).closest('.popup').removeClass('is-open');
    });

    $(document).on('click', '.js-remove-player', function () {
        const player_id = $(this).attr('player_id');
        const player = fbDB.players[player_id];

        $('#remove-player-player-name').html(player.name);
        $('#confirm-remove-player').attr('player_id', player_id);
        $('#remove-player-popup').addClass('is-open');
    });

    $(document).on('click', '#confirm-remove-player', function () {
        console.log('remove player');
        const player_id = $(this).attr('player_id');
        fbDB.remove(fbDB.playersPath + '/' + player_id);

        $('#remove-player-popup').removeClass('is-open');
    });

    $(document).on('click', '.btn-reject', function () {
        const user_id = $(this).attr('user_id');
        $.request('onRejectJoinStartedGame', {
            data: { user_id },
            success: function (res) {
                if (res.success) {
                } else {
                    console.log('An error occured');
                }
            },
            error: function (err) {
                console.log('An error occured : ', err);
            },
            complete: function (res) {
                $('#new-join-' + user_id).remove();
                if (!$('.new-member-list').length) {
                    $('#newMembers-popup').removeClass('is-open');
                }
            },
        });
    });

    $(document).on('click', '.btn-accept', function () {
        const user_id = $(this).attr('user_id');
        $.request('onAcceptJoinStartedGame', {
            data: { user_id },
            success: function (res) {
                if (res.success) {
                } else {
                    console.log('An error occured');
                }
            },
            error: function (err) {
                console.log('An error occured : ', err);
            },
            complete: function (res) {
                $('#new-join-' + user_id).remove();
                if (!$('.new-member-list').length) {
                    $('#newMembers-popup').removeClass('is-open');
                }
            },
        });
    });

    $(document).on('click', '#close-request-join-popup', function () {
        if (fbDB.pendingPlayers.length) {
            $('.teamMembers').addClass('is-new-members');
        }
    });
})(jQuery);
