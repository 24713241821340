var toolboxPopupSlider, syncSliderControls, calculateNotificationTotal;

$(document).render(function () {
    const isToolboxExist = $('.toolbox').length > 0;
    if (!isToolboxExist) return false;

    const elms = {
        documentItem: '.toolbox-popup .swiper-slide',
        documentThumbItem: '.toolbox-popup-thumb-item',
        documentControlPrev: '.toolbox-popup-prev',
        documentControlNext: '.toolbox-popup-next',
        documentControlTitle: '.toolbox-popup-control-title',
        documentControlRemove: '.toolbox-popup-control-remove',
        documentControlFullscreen: '.toolbox-popup-control-fullscreen',
        popupFullscreen: '.toolbox-fullscreen-popup',
    };

    // Init title for Slider Controls
    const initTitle = $('.toolbox-popup .swiper-slide').first().data('title');
    $(elms.documentControlTitle).html(initTitle);

    calculateNotificationTotal = () => {
        const document = $('#notification-document').data('count');
        const briefing = 0; //$('#notification-briefing').data('count');
        const total = parseInt(document) + parseInt(briefing);

        $('#notification-total').text('+' + total.toString());
        $('#notification-total').data('count', total);
        if (total) {
            $('#notification-badge-total').show();
        } else {
            $('#notification-badge-total').hide();
        }
    };

    $('.toolbox-toggle').click(function () {
        const $toolbox = $('.toolbox');
        const isOpen = $toolbox.hasClass('is-open');

        if (isOpen) {
            $toolbox.removeClass('is-open');
        } else {
            $toolbox.addClass('is-open');
        }
    });

    $('.toolbox-button.toolbox-open-document').click(function () {
        $('.toolbox').removeClass('is-open');
        $('.toolbox-popup').addClass('is-open');

        $('#notification-document').text('0');
        $('#notification-document').data('count', 0);
        $('#notification-badge-document').hide();
        calculateNotificationTotal();

        $.request('onResetNotificationDocument', {
            success: function () {},
        });
    });

    $('.toolbox-button.toolbox-open-briefing').click(function () {
        $('.toolbox').removeClass('is-open');
        $('.toolbox-briefing').addClass('is-open');

        $('#notification-briefing').text('0');
        $('#notification-briefing').data('count', 0);
        $('#notification-badge-briefing').hide();
        calculateNotificationTotal();

        $.request('onResetNotificationBriefing', {
            success: function () {},
        });
    });

    $('.toolbox-button.toolbox-open-phone').click(function () {
        $('.toolbox').removeClass('is-open');
        $('.toolbox-phone').addClass('is-open');
    });

    $('.toolbox-popup .popup-close').click(function () {
        $('.toolbox-popup').removeClass('is-open');
    });

    // ------------------------------------------------------------------------
    // Slider's Stuff
    // ------------------------------------------------------------------------
    toolboxPopupSlider = new Swiper('.toolbox-popup-slider', {
        init: false,
        touchRatio: 0,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    syncSliderControls = function () {
        const hasActiveIndex = toolboxPopupSlider && toolboxPopupSlider.activeIndex ? true : false;
        const currentIndex = hasActiveIndex ? parseInt(toolboxPopupSlider.activeIndex) : 0;
        const currentSlideDOM = toolboxPopupSlider.slides[currentIndex];
        const slidesLength = parseInt(toolboxPopupSlider.slides.length);
        const slideTitle = $(currentSlideDOM).data('title');
        const disabledRemove = slidesLength == 0;
        const disabledNext = currentIndex === slidesLength - 1 || slidesLength < 1 ? true : false;
        const disabledPrev = currentIndex === 0 ? true : false;

        $(elms.documentThumbItem.concat('.is-active')).removeClass('is-active');
        $(elms.documentThumbItem).eq(currentIndex).addClass('is-active');
        $(elms.documentControlRemove).attr('disabled', disabledRemove);
        $(elms.documentControlFullscreen).attr('disabled', disabledRemove);
        $(elms.documentControlNext).prop('disabled', disabledNext);
        $(elms.documentControlPrev).prop('disabled', disabledPrev);
        $(elms.documentControlTitle).html(slideTitle);

        // console.log('syncSliderControls', {
        //     disabledNext,
        //     disabledPrev,
        //     disabledRemove,
        //     slidesLength,
        // });
    };

    toolboxPopupSlider.on('init', syncSliderControls);
    toolboxPopupSlider.on('slideChange', syncSliderControls);
    toolboxPopupSlider.init();

    $(document).on('click', elms.documentThumbItem, function () {
        const targetIndex = $(this).index();

        $(elms.documentThumbItem.concat('.is-active')).removeClass('is-active');
        $(this).addClass('is-active');
        toolboxPopupSlider.slideTo(targetIndex);
    });

    $(elms.documentControlPrev).click(function () {
        toolboxPopupSlider.slidePrev();
    });

    $(elms.documentControlNext).click(function () {
        toolboxPopupSlider.slideNext();
    });

    $(elms.documentControlRemove).click(function () {
        // const $activeDoc = $('.swiper-slide.swiper-slide-active');
        const $activeDoc = $(elms.documentItem.concat('.swiper-slide-active'));
        const docTitle = $activeDoc.data('title');
        const docUrl = $activeDoc.attr('doc_url');

        // console.log('remove selected document, index: ', toolboxPopupSlider.activeIndex, {
        //     $activeDoc,
        //     docUrl,
        // });

        if (docUrl) {
            var data = {
                doc_title: docTitle,
                doc_url: docUrl,
            };

            /* PUSHER */
            // realtime.channel.trigger('client-remove-document', { ...data, local: false });
            // realtime.onRemoveDocument({ ...data, local: true });

            /* FIREBASE */
            fbDB.remove(fbDB.teamPath + '/sharedDoc/' + docTitle);

            $.request('onRemoveDocument', {
                data,
                success: function (data) {
                    // const eventData = { callback: 'hideShareDocumentBtnIfAlreadyShared' };
                    // realtime.channel.trigger('client-refresh-view-data', { ...eventData, local: false });
                    // realtime.onRefreshViewData({ ...eventData, local: true });
                },
                error: function (error) {
                    console.log(error);
                },
                complete: function () {},
            });
        }
    });

    $(elms.documentControlFullscreen).click(function () {
        const $activeDoc = $(elms.documentItem.concat('.swiper-slide-active'));
        const docUrl = $activeDoc.attr('doc_url');

        $(`${elms.popupFullscreen} img`).removeClass('is-active');
        $(`${elms.popupFullscreen} img[src="${docUrl}"]`).addClass('is-active');
        $(elms.popupFullscreen).addClass('is-open');
    });

    // ------------------------------------------------------------------------
    // Notification
    // ------------------------------------------------------------------------
    $('.toolbox-notification-dismiss').click(function () {
        $('.toolbox-notification').removeClass('is-open');
    });

    $('.toolbox-notification-open').click(function () {
        $('.toolbox-notification').removeClass('is-open');
        $('.toolbox-popup').addClass('is-open');
    });

    // ------------------------------------------------------------------------
    // Phonecall
    // ------------------------------------------------------------------------
    $('.phone-call').on('submit', function (e) {
        e.preventDefault();
        const ceoNumber = $('.phone-call').data('ceo-number');
        const inputNumber = $(".phone-call input[name='number']").val();
        const isCalling = $('.phone').hasClass('is-calling');

        if (!isCalling) {
            $.request('onCheckCeoNumber', {
                data: {
                    ceo_number: inputNumber,
                },
                success: function (res) {
                    if (res.success && res.is_correct) {
                        const data = { audio: res.audio };
                        /* PUSHER */
                        // realtime.channel.trigger('client-call-ceo', { ...data, local: false });
                        $('#brief-title-4').addClass('is-correct');

                        // realtime.channel.trigger('client-go-to-phase4', {});
                        // realtime.onGoToPhase4();

                        /* FIREBASE */
                        fbDB.update(fbDB.teamPath, { callingCEO: res.audio });
                        setTimeout(function () {
                            fbDB.remove(fbDB.teamPath + '/callingCEO');
                        }, 3000);
                    } else {
                        $('.phone-call-wrongnumber').addClass('is-active');
                    }
                },
                error: function (error) {},
                complete: function () {},
            });

            // if (inputNumber === ceoNumber) {
            //     $('.phone').addClass('is-calling');
            //     $fieldset.prop('disabled', true);

            //     setTimeout(() => {
            //         $('.phone').addClass('is-voicemail');
            //     }, 800);
            // } else {
            //     $('.phone-call-wrongnumber').addClass('is-active');
            // }
        }
    });

    $('.phone-call-field-delete').click(function () {
        const $input = $(".phone-call input[name='number']");
        const val = $input.val();
        const newVal = val.slice(0, val.length - 1);
        $input.val(newVal).trigger('change');
    });

    $('.phone-call-body-keyboard .row button').click(function () {
        const $input = $(".phone-call input[name='number']");
        const number = $(this).text();
        const val = $input.val();
        const maxLength = parseInt($input.attr('maxlength'));
        if (val.length < maxLength) $input.val(val + number).trigger('change');
    });

    $(".phone-call input[name='number']").on('input change', function () {
        const empty = $(this).val().length == 0;
        if (empty) {
            $('.phone-call-field-delete').addClass('is-hidden');
        } else {
            $('.phone-call-field-delete').removeClass('is-hidden');
        }
    });

    // ------------------------------------------------------------------------
    // Voicemail
    // ------------------------------------------------------------------------
    /**
     * Convert duration length to readable format mm:ss
     * @param {number} duration
     */
    function formatDuration(duration) {
        const minute = Math.floor(duration / 60);
        const second_int = parseInt(duration - minute * 60);
        const second = second_int < 10 ? '0' + second_int : second_int;
        const time = minute + ':' + second;

        return time;
    }

    const $elms = {
        voicemail: $('.voicemail'),
        voicemailPlayer: $('.voicemail-player'),
        voicemailSeekbar: $('.voicemail-seekbar'),
        voicemailTime: $('.voicemail-time'),
        voicemailButton: $('.voicemail-play'),
        voicemailClose: $('#close-call'),
    };

    $elms.voicemailPlayer.on('loadedmetadata', function () {
        const duration = $elms.voicemailPlayer[0].duration;
        $elms.voicemailTime.find('.end').text(formatDuration(duration));
    });

    $elms.voicemailPlayer.on('timeupdate', function () {
        const currentTime = $elms.voicemailPlayer[0].currentTime;
        const totalTime = $elms.voicemailPlayer[0].duration;
        const percentage = (currentTime / totalTime) * 100;

        $elms.voicemailTime.find('.start').text(formatDuration(currentTime));
        $elms.voicemailSeekbar.find('span').css('width', percentage + '%');
    });

    $elms.voicemailPlayer.on('ended', function () {
        $elms.voicemail.removeClass('is-playing');
        $elms.voicemailButton.removeAttr('disabled');
    });

    $elms.voicemailButton.click(function () {
        const isPlaying = $elms.voicemail.hasClass('is-playing');

        if (!isPlaying) {
            $elms.voicemailPlayer[0].currentTime = 0;
            $elms.voicemailPlayer[0].play();
            $elms.voicemail.addClass('is-playing');
            $elms.voicemailButton.prop('disabled', true);

            $.request('onCallCEO', {
                data: {},
                success: function (res) {},
                error: function (error) {},
            });
        }
    });

    $elms.voicemailClose.on('click', function () {
        if ($elms.voicemailPlayer.length) {
            $elms.voicemailPlayer[0].pause();

            if ($('#brief-title-4').hasClass('is-correct')) {
                // realtime.channel.trigger('client-go-to-phase4', {});
                // realtime.onGoToPhase4();
                fbDB.update(fbDB.teamPath, { currentPhase: 4 });
            }
        }
    });
});
